<template>
  <VModalWidget :dismiss="dismiss" :submit="submit" :submit-title="$t('labels.create')" content-class="pa-0">
    <template v-slot:title>{{ $t('labels.note') }}</template>
    <template v-slot:content>
      <tiptap-vuetify
        v-model="note.text"
        :extensions="extensions"
        :placeholder="$t('labels.addNote')"
        :toolbar-attributes="{ color: 'grey lighten-3' }"
        :card-props="{ flat: true }"
      />
      <div class="pa-4">
        <v-switch
          hide-details
          color="success"
          v-model="note.private"
          class="shrink mr-2 mt-0"
          :label="$t('labels.private')"
        />
      </div>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { VModalWidget } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Blockquote,
  ListItem,
  BulletList,
  OrderedList,
  HorizontalRule
} from 'tiptap-vuetify'
import { NAMESPACE } from '../store'

export default {
  name: 'ModalLeadNote',
  mixins: [Modal],
  components: {
    VModalWidget,
    TiptapVuetify
  },
  data: () => ({
    note: {
      text: '',
      private: false
    },
    extensions: [
      Bold,
      Italic,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      ListItem,
      Blockquote,
      BulletList,
      OrderedList,
      HorizontalRule
    ],
    loading: false
  }),
  methods: {
    ...mapActions(NAMESPACE, ['addNote']),
    submit() {
      this.loading = true
      this.addNote(this.note).then(() => {
        this.dismiss()
      })
    }
  }
}
</script>
